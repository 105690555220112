import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  // Uncomment as needed
  // {
  //   path: '/test1',
  //   name: 'test1',
  //   component: () => import('../views/AboutView.vue'),
  // },
  {
    path: '/service/:pdr/:keyopen',
    name: 'service',
    component: () => import('../views/test2.vue'),
  },
  // Uncomment as needed
  // {
  //   path: '/test0',
  //   name: 'test0',
  //   component: () => import('../views/TestZero.vue'),
  // },
  {
    path: '/:pathMatch(.*)*', // Catch-all route
    name: 'NotFound',
    component: () => import('../views/NotFound.vue'), // Ensure you have this component
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
